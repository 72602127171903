import React, { Component } from 'react';
import { loadBuyButton } from './buy-button';


export const BuyButton = class extends Component {

	componentDidMount() {
		loadBuyButton();
	}

	render() {
		return (
			<div id='collection-component-1600805166191' />
		);
	}
}
