import React from 'react';
import './App.css';
import logo from './logo.jpg';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai';
import { GiSwapBag } from 'react-icons/gi';
import { FaLeaf, FaHandHoldingHeart } from 'react-icons/fa';
import { BsChevronDown } from 'react-icons/bs';
import { BuyButton } from './BuyButton';

function App() {
	const scroll = () => document.getElementById("buy-button-anchor").scrollIntoView({
		behavior: 'smooth'
	});
	return (
		<>
			<nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark static-top">
				<div className="container">
					<a className="navbar-brand" href="https://shanolagranola.com">
						<img height="40px" src={logo} alt="Shanola Granola Logo" />
					</a>
					<div className="navbar-nav ml-auto">
						<IconContext.Provider value={{ size: '40px' }}>
							<div className="nav-item active">
								<a className="nav-link" target="_top" href="https://www.facebook.com/Shanola-Granola-100659661668306"><AiFillFacebook /></a>
							</div>
							<div className="nav-item active">
								<a className="nav-link" target="_top" href="https://www.instagram.com/shanolagranola/"><AiFillInstagram /></a>
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</nav>
			<Container fluid>
				<Row id='hero' className={'text-light'}>
					<div className={'overlay'} />
					<Col xs={12} className={'text-center'}>
						<h1>Shanola Granola</h1>
						<p>A granola made with love, to spread love <span role="img" aria-label="heart">❤️</span></p>
						<p><IconContext.Provider value={{ size: '20px' }}>
							<Button id="shop-now-btn-sm" variant="outline-light" onClick={scroll}>Shop Now! <BsChevronDown /></Button>
							<Button id="shop-now-btn-lg" variant="outline-light" size="lg" onClick={scroll}>Shop Now! <BsChevronDown /></Button>
						</IconContext.Provider></p>
					</Col>
				</Row>
				<Row id='blurb'>
					<Col>
						<IconContext.Provider value={{ size: '40px' }}>
							<div className={'blurb-box'}>
								<div className={'blurb-item'}>
									<span className={'blurb-icon'}><GiSwapBag /></span>
									<span className={'blurb-description'}><h6>Made with love</h6>Handmade by a pediatric nurse in Delmar, NY</span>
								</div>

								<div className={'blurb-item'}>
									<span className={'blurb-icon'}><FaLeaf /></span>
									<span className={'blurb-description'}><h6>Guilt-free</h6>Made with <a href="https://www.healthline.com/nutrition/mct-oil-benefits">MCT oil</a> that burns fat and fuels your brain</span>
								</div>
								<div className={'blurb-item'}>
									<span className={'blurb-icon'}><FaHandHoldingHeart /></span>
									<span className={'blurb-description'}><h6>Spreading love</h6>5% of profits <a href="https://www.amc.edu/patient/services/childrens_hospital/pediatric_hematology_oncology/index.cfm">donated</a> to help children with cancer</span>
								</div>
							</div>
						</IconContext.Provider>
					</Col>
					<div id="buy-button-anchor" />
				</Row>
				<Row id='buy-button'>
					<h1>Shop our Granola</h1>
					<BuyButton />
				</Row>
				<Row id='footer' className={'bg-dark text-light'}>
					<Col className={'text-center font-weight-bold'}>Made with love <span role='img' aria-label='heart leaf'>💙🌱</span></Col>
				</Row>
			</Container>
		</>
	);
}

export default App;
