/* eslint-disable */
export const loadBuyButton = () => {
	var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
	if(window.ShopifyBuy) {
		if(window.ShopifyBuy.UI) {
			ShopifyBuyInit();
		} else {
			loadScript();
		}
	} else {
		loadScript();
	}

	function loadScript() {
		var script = document.createElement('script');
		script.async = true;
		script.src = scriptURL;
		(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
		script.onload = ShopifyBuyInit;
	}

	function ShopifyBuyInit() {
		var client = ShopifyBuy.buildClient({
			domain: 'shanola-granola.myshopify.com',
			storefrontAccessToken: 'e1eb6add54b8d8e3f6a67b5ebcd048e3',
		});
		ShopifyBuy.UI.onReady(client).then(function(ui) {
			ui.createComponent('collection', {
				id: '212525056152',
				node: document.getElementById('collection-component-1600805166191'),
				moneyFormat: '%24%7B%7Bamount%7D%7D',
				options: {
					"product": {
						"styles": {
							"product": {
								"margin-bottom": "30px",
								"@media (min-width: 601px)": {
									"max-width": "calc(33.33333% - 30px)",
									"margin-left": "30px",
									"margin-bottom": "50px",
									"width": "calc(33.33333% - 30px)"
								},
								"img": {
									"height": "calc(100% - 15px)",
									"position": "absolute",
									"left": "0",
									"right": "0",
									"top": "0"
								},
								"imgWrapper": {
									"padding-top": "calc(75% + 15px)",
									"position": "relative",
									"height": "0"
								}
							},
							"title": {
								"font-size": "20px"
							},
							"button": {
								":hover": {
									"background-color": "#3a9bbe"
								},
								"background-color": "#40acd3",
								":focus": {
									"background-color": "#3a9bbe"
								},
								"border-radius": "5px"
							}
						},
						"buttonDestination": "modal",
						"contents": {
							"options": false
						},
						"text": {
							"button": "Details"
						}
					},
					"productSet": {
						"styles": {
							"products": {
								"@media (min-width: 601px)": {
									"margin-left": "-30px"
								}
							}
						}
					},
					"modalProduct": {
						"contents": {
							"img": false,
							"imgWithCarousel": true,
							"button": false,
							"buttonWithQuantity": true
						},
						"styles": {
							"product": {
								"@media (min-width: 601px)": {
									"max-width": "100%",
									"margin-left": "0px",
									"margin-bottom": "0px"
								}
							},
							"button": {
								":hover": {
									"background-color": "#3a9bbe"
								},
								"background-color": "#40acd3",
								":focus": {
									"background-color": "#3a9bbe"
								},
								"border-radius": "5px"
							}
						},
						"text": {
							"button": "Add to cart"
						}
					},
					"cart": {
						"styles": {
							"button": {
								":hover": {
									"background-color": "#3a9bbe"
								},
								"background-color": "#40acd3",
								":focus": {
									"background-color": "#3a9bbe"
								},
								"border-radius": "5px"
							},
							"title": {
								"color": "#565656"
							},
							"header": {
								"color": "#565656"
							},
							"lineItems": {
								"color": "#565656"
							},
							"subtotalText": {
								"color": "#565656"
							},
							"subtotal": {
								"color": "#565656"
							},
							"notice": {
								"color": "#565656"
							},
							"currency": {
								"color": "#565656"
							},
							"close": {
								"color": "#565656",
								":hover": {
									"color": "#565656"
								}
							},
							"empty": {
								"color": "#565656"
							},
							"noteDescription": {
								"color": "#565656"
							},
							"discountText": {
								"color": "#565656"
							},
							"discountIcon": {
								"fill": "#565656"
							},
							"discountAmount": {
								"color": "#565656"
							}
						},
						"text": {
							"total": "Subtotal",
							"notice": "Thanks for supporting Shanola!\nShipping and discount codes are added at checkout.",
							"button": "Checkout",
							"noteDescription": "Special instructions for Shannon"
						},
						"contents": {
							"note": true
						},
						"popup": false
					},
					"toggle": {
						"styles": {
							"toggle": {
								"background-color": "#40acd3",
								":hover": {
									"background-color": "#3a9bbe"
								},
								":focus": {
									"background-color": "#3a9bbe"
								}
							}
						}
					},
					"lineItem": {
						"styles": {
							"variantTitle": {
								"color": "#565656"
							},
							"title": {
								"color": "#565656"
							},
							"price": {
								"color": "#565656"
							},
							"fullPrice": {
								"color": "#565656"
							},
							"discount": {
								"color": "#565656"
							},
							"discountIcon": {
								"fill": "#565656"
							},
							"quantity": {
								"color": "#565656"
							},
							"quantityIncrement": {
								"color": "#565656",
								"border-color": "#565656"
							},
							"quantityDecrement": {
								"color": "#565656",
								"border-color": "#565656"
							},
							"quantityInput": {
								"color": "#565656",
								"border-color": "#565656"
							}
						}
					}
				},
			});
		});
	}
};
